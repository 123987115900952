<template>
  <page-head-wrapper>
     <!-- 操作栏 -->
    <template v-slot:handles-btns>
      <a-button size="small" style="margin-right: 16px" @click="resetPwd"><i class="e-icon refresh-pwd"></i>重置密码</a-button>
      <a-button size="small" @click="onRefresh"><i class="e-icon refresh"></i>刷新</a-button>
    </template>
    <a-spin :spinning="loading">
      <div class="mp-detail-module">
        <div class="module-top">
          <h3 class="mod-ttile">应用详情</h3>
          <div class="mod-handles">
            <a-button v-if="!canEditable" type="link" size="small" @click="editHandle"
            ><i class="e-icon edit-blue"></i>编辑</a-button>
            <template v-else>
              <a-button type="link" size="small" @click="onSubmit">保存</a-button>
              <a-button type="link" size="small" class="grey ml-8" @click="canEditable = false">取消</a-button>
            </template>
          </div>
        </div>
        <div class="module-main pdm-32 bdm">
          <a-form-model
            :model="nickForm"
            layout="inline"
          >
            <a-row :gutter="16">
              <a-col :span="8">
                <a-form-model-item label="手机号">
                  <span class="item-text">{{detailData.userCellphone || '-'}}</span>
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label="用户昵称">
                  <span class="item-text" v-if="!canEditable">{{detailData.userNickname || '-'}}</span>
                  <a-input v-else
                    placeholder="请输入用户昵称"
                    v-model="nickForm.nick"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label="所属企业">
                  <span class="item-text">{{detailData.companyNames || '-'}}</span>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row :gutter="16">
              <a-col :span="8">
                <a-form-model-item label="账户来源">
                  <span class="item-text">{{userSources[detailData.userSource]}}</span>
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label="关联应用">
                  <span class="item-text" v-if="relatedAppsString.length < 10 ">{{relatedAppsString}}</span>
                  <a-tooltip v-else placement="topLeft" :title="relatedAppsString">
                    <span class="item-text">{{relatedAppsString.slice(0, 10) + '...'}}</span>
                  </a-tooltip>
                  
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label="注册时间" prop="applicationEmail">
                  <span class="item-text">{{detailData.userRegisterTime}}</span>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row  :gutter="16">
              <a-col :span="8">
                <a-form-model-item label="最后登录时间" prop="applicationState">
                  <span class="item-text">{{detailData.userLastLoginTime}}</span>
                </a-form-model-item>
              </a-col>
							<a-col :span="8">
                <a-form-model-item label="账户状态" prop="applicationState">
                  <span class="item-text">{{detailData.userStatus == 'y' ? '启用' : '禁用'}}</span>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </div>
      </div>
    </a-spin>

		<loginLog v-if="logShow" />
    
  </page-head-wrapper>
</template>

<script>
import {userDetail, updateNickname, resetPwd} from '@/api/user'
import {mapGetters} from 'vuex'
import loginLog from './login-log'
import {relatedApps, userSources} from './user-config'
export default {
  name: 'static-data-user-detail', 
  keep_alive_tab: true,
  computed: {
    ...mapGetters(['adminId']),
    relatedAppsString: function() {
      if (this.detailData.relatedApplications) {
        return this.detailData.relatedApplications.split(',').map(key => this.relatedApps[key]).join('、')
      } else {
        return '-'
      }
    }
  },
  components: {
		loginLog
  },
  data() {
    return {
			loading: false,
      canEditable: false, // 详情编辑
			detailData: {},
      nickForm: {
        userId: '',
        phone: '',
        nick: ''
      },
      id: '',
      // 关联应用
			relatedApps,
			// 账户来源
			userSources,

      logShow: true,
    };
  },
	created() {
    console.log('detail: created')
    console.log('this.id', this.id )
    if (this.$route.name == 'static-data-user-detail' && this.$route.query.id && this.id !== this.$route.query.id ) {
      this.id = this.$route.query.id
      this.getDetail()
    }
	},
  mounted() {
    console.log('detail: mounted')
  },
  activated() {
    console.log('detail: activated')
  },
  deactivated() {
    console.log('detail: deactivated')
  },
  watch: {

  },
  methods: {
    onRefresh() {
      this.getDetail()
      this.logShow = false
      this.$nextTick(() => {
        this.logShow = true
      })
    },
		getDetail() {
			this.loading = true
			userDetail({
				userId: this.id
			}).then(res => {
				console.log(res)
				if (res.code === 200) {
					this.detailData = res.data
          this.$store.dispatch('updateTabTitle', {
            force: true,
            path: this.$route.fullPath,
            title: '用户-' + this.detailData.userCellphone
          })
				} else {
					this.$message.error(res.msg || '网络异常，请稍后重试')
				}
			}).catch(err => {
				console.warn(err)
			}).finally(() => {
				this.loading = false
			})
		},
    editHandle() {
      this.canEditable = true
      this.nickForm.nick = this.detailData.userNickname || ''
    },
    // 提交编辑 -  昵称编辑
    onSubmit() {
      console.log('submit')
      if (this.nickForm.nick.trim() === '') {
        this.$message.error('昵称输入不能为空')
        return
      }
      this.$showLoading()
      updateNickname({
        userId: this.id,
        nickname: this.nickForm.nick
      }).then(res => {
        if (res.code === 200) {
          this.$message.success('编辑成功')
          this.canEditable = false
          this.getDetail()
        } else {
          this.$message.error(res.msg || '操作失败')
        }
      }).catch(err => {
        console.warn(err)
      }).finally(() => {
        this.$hideLoading()
      })
    },

    // 重置密码
    resetPwd() {
      this.$confirm({
        title: "确认重置该账户密码？",
				// content: "重置后，会随机生成一个新密码，并以短信告知用户",
        content: h => <div style="color: #666">重置后，会随机生成一个新密码，并以短信告知用户</div>,
        centered: true,
        onOk: () => {
          console.log("OK");
					this.$showLoading()
					resetPwd({
						userId: this.id,
						adminId: this.adminId
					}).then(res => {
						console.log(res)
						if (res.code === 200) {
							this.$message.success('密码重置成功')
						} else {
							this.$message.error(res.msg || '密码重置失败')
						}
					}).catch(err => {
						console.warn(err)
					}).finally(() => {
						this.$hideLoading()
					})
        },
        onCancel: () => {
          console.log("Cancel");
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.item-text {
	color: #333;
	line-height: 22px;
}
.bottom-btn-wrap {
  padding: 40px 0;
}
</style>